.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.theme_dropdown {
  width: 250px;
}

.theme_dropdown div {
  cursor: pointer;
  z-index: 2;
}

/*
.css-1pahdxg-control:hover{
  border-color: #333 !important;
}*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden;
  color: #818181;
  font-family: "Open Sans", sans-serif !important;
}

.page_section {
  padding: 30px;
  position: relative;
  min-height: 83vh;
}

.main_title {
  font-size: 26px;
  margin-bottom: 25px;
  font-weight: 600;
  color: #7f7f7f;
}

.desc_data h5,
h5 {
  color: #7f7f7f;
  margin-bottom: 0;
}

.remove_margin {
  margin-bottom: 0px !important;
}

body,
p {
  font-size: 16px;
}

.city_title {
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  width: 100%;
  padding: 13px 15px;
  background: linear-gradient(180deg,
      rgb(0 0 0 / 45%),
      rgb(0 0 0 / 36%) 35%,
      rgb(0 0 0 / 23%));
}

.lang-selection {
  border: 0;
  padding: 5px;
}

.desc_data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.city_img_bind {
  width: 100%;
  height: 300px;
  position: relative;
  cursor: pointer;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_15 {
  margin-bottom: 15px;
}

.city_title h4 {
  font-size: 18px;
}

.city_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header_section {
  box-shadow: 0 4px 12px 3px hsl(0deg 0% 87% / 62%);
}

.copyright_text {
  border-top: 1px solid #eaeaea;
  background: #212121;
  padding: 10px 30px;
  width: 100%;
  position: absolute;
}

.white_text {
  color: #fff;
}

.coverImage img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.audioNumber {
  /* color: #0b11f3; */
  position: relative;
  /* margin-right: 8px; */
  /* padding-right: 8px; */
}

.audioNumber::after {
  /* content: "|"; */
  position: absolute;
  width: 3px;
  height: 100%;
  top: -3px;
  right: 0;
  color: #7f7f7f;
}

.blue-text {
  color: #0b11f3;
}

.back_option_page {
  border: 1px solid #7f7f7f;
  color: #7f7f7f;
  margin-bottom: 25px;
}

.owl-nav button {
  background: #22cbc7 !important;
  color: #fff !important;
  margin-right: 6px !important;
  border: 1px solid #22cbc7 !important;
  font-size: 10px !important;
  height: 45px;
  width: 45px;
  line-height: 10px !important;
}

.owl-nav button.owl-prev {
  margin-left: 0 !important;
}

.owl-theme .owl-nav {
  text-align: left;
}

.owl-theme .owl-nav span {
  font-size: 34px;
}

.icon_setup {
  position: absolute;
  right: 5px;
  top: 5px;
}

.icon_setup span {
  background-color: #333;
  color: #fff;
  border-radius: 50px;
  padding: 4px 5px 6px 6px;
  font-size: 12px;
  margin: 2px;
  border: 1px solid #fff;
}

.icon_setup span:hover {
  background-color: #22cbc7;
}

.date-desc {
  background-color: #ededed;
  border-radius: 5px;
  padding: 7px 10px;
  width: fit-content;
  margin-top: 5px;
}

.city_title p {
  font-size: 14px;
}

.audio_section audio {
  border: 1px solid #dee2e6;
  border-radius: 7px;
  background: #f1f3f4;
  margin-bottom: 15px;
}

.video_section {
  background-color: #f5f5f5;
  margin-bottom: 15px;
}

.video_section video {
  border-radius: 7px;
  height: 450px;
}

.custom-select {
  background-color: #f1f3f4;
  color: #7f7f7f;
  padding: 8px 5px;
  border: 1px solid #dee2e6;
  box-sizing: content-box;
  border-radius: 3px;
}

.custom-select:focus {
  outline: none;
  border: 1px solid #dee2e6;
}

select {
  width: 250px;
}

option {
  width: 250px;
}

.sweet-loading {
  border: 10px solid #fceee7;
  border-radius: 50%;
  border-top: 10px solid #ff8548;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: fixed;
  z-index: 999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.event-img-container {
  width: 100%;
  height: 500px;
}

.event-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.height-75vh-section {
  height: 83vh;
}

.navbar-expand-xl .navbar-collapse.collapse {
  display: none !important;
}

.navbar-expand-xl .navbar-toggler {
  display: block;
  transition: all 0.3s ease-in-out;
}

.navbar-expand-xl .navbar-collapse {
  flex-basis: 100% !important;
  flex-grow: 1;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 11;
  text-align: center;
  display: block !important;
  padding: 50px;
}

.nav-link {
  padding: 1rem 1rem;
}

.closeMenu {
  position: absolute;
  right: 65px;
  top: 40px;
  border: 1px solid #333;
}

.titleIntro-section {
  padding: 30px 0 0;
}

body.openMenu {
  overflow: hidden;
}

.sticky-section {
  position: sticky;
  width: 100%;
  top: -1px;
  background: #f9f9f9;
  z-index: 10;
  padding: 25px 0 15px;
  box-shadow: 0px 0px 10px #00000036;
}

.stickyHeadMenu .navbar-toggler {
  position: fixed;
  right: 40px;
  top: 25px;
  z-index: 11;
}

#stickyHead {
  transition: all 0.3s ease-in-out;
}

.sticky-section .intoduction-data {
  display: none;
}

.navbar-expand-xl {
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar-expand-xl .navbar-nav {
  flex-direction: column;
}

/* image gallery css :: start */
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 30px !important;
}

.image-gallery-thumbnail {
  width: 70px !important;
}

.image-gallery-slides {
  background-color: #f5f5f5;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  outline: none !important;
  border: 3px solid #04151f !important;
}

.image-gallery-fullscreen-button {
  right: 5px;
  bottom: 30px;
  padding: 0;
}

.image-gallery-play-button {
  left: 5px;
  bottom: 30px;
  padding: 0;
}

.image-gallery-slides {
  width: 100%;
  height: 400px;
  margin: auto;
  padding: 30px 0;
}

.image-gallery-content.fullscreen .image-gallery-slides {
  height: 100%;
}

/* Navbar css */
.navbar-nav {
  position: absolute;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-nav a {
  font-size: 22px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .custom-select {
    width: 150px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .main_title {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .custom-select option {
    width: 150px;
  }

  .desc_data {
    display: block;
  }

  .closeMenu {
    right: 15px;
    top: 30px;
  }

  #stickyHead {
    padding-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .stickyHeadMenu .navbar-toggler {
    right: 15px;
  }

  .theme_dropdown {
    margin-bottom: 15px;
  }

  .description,
  .desc_data h5 {
    margin-bottom: 15px !important;
  }
}